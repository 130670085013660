import React from "react";
import { useEffect } from 'react';
import { useRouter } from 'next/router';
import Image from "next/image"

const Custom404: React.FC = () => {

    const router = useRouter();

    useEffect(() => {
      // const redirectToLogin = setTimeout(() => {
      //   router.push('/');
      // }, 0); 
  
      // return () => clearTimeout(redirectToLogin);
      router.push('/');
    }, []);

    return (
      <div className="w-screen h-screen flex justify-center items-center flex-col gap-y-6">
        {/* <h1 className="text-4xl text-black">{"Oops! The page you're looking for does not exist."}</h1>
        <div className="relative w-[600px] h-[600px] bg-black">
        <Image src={"/img/artwork/404.webp"} alt="404 image" layout="fill"></Image>
        </div> */}
      </div>
    );
  };
  
  export default Custom404;